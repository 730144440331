<template>
  <div class="row-data d-flex flex-column mt-4 mb-6">
    <div class="d-flex flex-row-space-between">
      <div class="d-flex flex-column">
        <ChildInfo :child-number="getChildNumber" />

        <ServiceVarietyReservations
          v-for="variety in serviceVarieties"
          :key="variety.name"
          :placement="currentProposition.placement"
          :service-variety-name="variety.name"
          :label="variety.label"
        />
      </div>

      <ProductsInfo
        :products="products"
        :showProductsInfo="usesFlexkidsProducts()"
        :service-products="serviceProducts"
      />
      <SelectedDaysInfo :current-proposition="currentProposition" />
      <StatusInfo :current-proposition="currentProposition" />
    </div>

    <div class="d-flex-full-width flex-row align-items-start">
      <PriorityInfo
        :current-proposition="currentProposition"
        :possible-priorities="possiblePriorities"
      />

      <CancelReasonData
        :current-proposition="currentProposition"
        class="cancel-reason-data"
      />

      <ActionButtons
        class="action-buttons"
        :can-manage-waiting-list="canManageWaitingList"
        :current-proposition="currentProposition"
        :can-propose="canPropose"
        @cancel-clicked="onCancelClicked"
        @propose-clicked="onProposeClicked"
        @revoke-clicked="onRevokeClicked"
      />
    </div>

    <ProposePropositionDialog
      v-if="showProposeDialog"
      :show-propose-dialog="showProposeDialog"
      :current-proposition="currentProposition"
      :planner-action-error="plannerActionError"
      @notify-accepted="OnWaitinglistPropositionProposedAsync"
      @canceled="onPropositionActionCancelled"
    />

    <CancelPropositionDialog
      v-if="showCancelDialog"
      :show-cancel-dialog="showCancelDialog"
      :current-proposition="currentProposition"
      :cancel-reasons="cancelReasons"
      :comment-max-length="commentMaxLength"
      :planner-action-error="plannerActionError"
      @notify-accepted="OnWaitinglistPropositionCancelledAsync"
      @canceled="onPropositionActionCancelled"
      @causedByReasonTypeChanged="onCausedByReasonTypeChanged"
      @causedByReasonCommentChanged="onCausedByReasonCommentChanged"
    />

    <RevokePropositionDialog
      v-if="showRevokeDialog"
      :show-revoke-dialog="showRevokeDialog"
      :current-proposition="currentProposition"
      :revoke-reasons="revokeReasons"
      :comment-max-length="commentMaxLength"
      :planner-action-error="plannerActionError"
      @notify-accepted="OnWaitinglistPropositionRevokedAsync"
      @canceled="onPropositionActionCancelled"
      @causedByReasonTypeChanged="onCausedByReasonTypeChanged"
      @causedByReasonCommentChanged="onCausedByReasonCommentChanged"
    />
  </div>
</template>

<script lang="ts">
import { ServiceVarietyName, ServiceKind, WaitingListProposition, Product, ServiceProduct } from '@/models'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import PartouStepper from '@/components/PartouComponents/PartouStepper.vue'
import Permission from '@/models/enums/Permission'
import { PlannerCausedByReason } from '@/models/enums/PlannerCausedBy'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { IWaitingListPropositionService } from '@/services/WaitingListPropositionService/IWaitingListPropositionService'
import { IConfigurationService } from '@/services/ConfigurationService/IConfigurationService'
import PartouFloatingCircleButton from '@/components/PartouComponents/PartouFloatingCircleButton.vue'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import PartouTextArea from '@/components/PartouComponents/PartouTextArea.vue'
import TextAreaFieldRow from '@/components/TextAreaFieldRow'
import PartouButton from '@/components/PartouComponents/PartouButton.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import WaitingListPlacePriority from '@/models/enums/WaitingListPlacePriority'
import { PriorityOption } from '@/pages/ServicesPage/WaitingList/PriorityOption'
import WaitingListPropositionStatus from '@/models/enums/WaitingListPropositionStatus'
import { WaitingListPropositionResponse } from '@/services/WaitingListPropositionService/WaitingListPropositionResponse'
import { IPropositionService } from '@/services/PropositionService/IPropositionService'
import ChildInfo from './ExpandedComponents/ChildInfo.vue'
import ServiceVarietyReservations from './ExpandedComponents/ServiceVarietyReservations.vue'
import ProductsInfo from './ExpandedComponents/ProductsInfo.vue'
import SelectedDaysInfo from './ExpandedComponents/SelectedDaysInfo.vue'
import StatusInfo from './ExpandedComponents/StatusInfo.vue'
import PriorityInfo from './ExpandedComponents/PriorityInfo.vue'
import CancelReasonData from './ExpandedComponents/CancelReasonData.vue'
import ActionButtons from './ExpandedComponents/ActionButtons.vue'
import CancelPropositionDialog from './ExpandedComponents/Dialogs/CancelPropositionDialog.vue'
import ProposePropositionDialog from './ExpandedComponents/Dialogs/ProposePropositionDialog.vue'
import RevokePropositionDialog from './ExpandedComponents/Dialogs/RevokePropositionDialog.vue'

@Component({
  components: { ProposePropositionDialog, CancelPropositionDialog, RevokePropositionDialog, ActionButtons, CancelReasonData, PriorityInfo, StatusInfo, SelectedDaysInfo, ProductsInfo, ServiceVarietyReservations, ChildInfo, DaysCoverage, PartouStepper, PartouFloatingCircleButton, PartouAutocomplete, PartouButton, PartouDialog, TextAreaFieldRow, PartouTextArea }
})
export default class WaitingListPropositionTableExpanded extends Vue {
  @Prop({ required: true })
  proposition!: WaitingListProposition

  @Prop({ required: true })
  serviceProducts!: ServiceProduct[]

  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  waitingListPropositionService: IWaitingListPropositionService = container.get<IWaitingListPropositionService>(SERVICE_IDENTIFIERS.IWaitingListPropositionService)
  propositionService: IPropositionService = container.get<IPropositionService>(SERVICE_IDENTIFIERS.IPropositionService)

  ServiceVarietyName = ServiceVarietyName
  ServiceKind = ServiceKind

  showProposeDialog = false
  showCancelDialog = false
  showRevokeDialog = false

  causedByReasonComment = ''
  causedByReasonType = ''
  commentMaxLength = 125
  cancelReasons: PlannerCausedByReason[] = []
  revokeReasons: PlannerCausedByReason[] = []
  possiblePriorities: PriorityOption[] = []
  products: Product[] = []
  currentProposition!: WaitingListProposition
  plannerActionError = false
  hasUnsupportedProducts = false

  serviceVarieties = [
    { name: ServiceVarietyName.NSO, label: 'startNSO' },
    { name: ServiceVarietyName.VSO, label: 'startVSO' },
    { name: ServiceVarietyName.KDV, label: 'startKDV' }
  ]

  get getChildNumber () {
    return this.currentProposition.locationInquiry.child?.number
  }

  async created () {
    this.currentProposition = this.proposition
    this.products = this.getProducts()
    this.hasUnsupportedProducts = await this.hasUnsupportedProductsAsync(this.currentProposition)
    for (const waitingListPlacePriority in WaitingListPlacePriority) {
      this.possiblePriorities.push({ value: waitingListPlacePriority, text: this.$t(`waitingListPropositions.table.priority.${waitingListPlacePriority.toLowerCase()}`).toString() })
    }
  }

  mounted () {
    this.getConfigurationTableDataAsync()
  }

  get canManageWaitingList () : boolean {
    return this.authService.hasPermission([Permission.ManageWaitingList])
  }

  isUnsupportedProduct (productId: string) : boolean {
    const serviceId = this.proposition.locationInquiry.booking.serviceId ?? ''
    const serviceProduct = this.serviceProducts.find(serviceProduct => serviceProduct.productId === productId && serviceProduct.serviceId === serviceId)
    return !serviceProduct?.isBookable
  }

  async hasUnsupportedProductsAsync (proposition: WaitingListProposition) : Promise<boolean> {
    if (!this.usesFlexkidsProducts()) {
      return false
    }

    for (const waitingListPlace of proposition.locationInquiry.waitingListPlaces) {
      const product = waitingListPlace.product
      if (product && this.isUnsupportedProduct(product.id)) {
        return true
      }
    }
    return false
  }

  get canPropose () : boolean {
    return !this.hasUnsupportedProducts
  }

  usesFlexkidsProducts () : boolean {
    return this.proposition.locationInquiry?.waitingListPlaces?.some(waitingListPlace => waitingListPlace.product != null) ?? false
  }

  getProducts () : Product[] {
    const productsWaitingListPlaces = this.currentProposition.locationInquiry?.waitingListPlaces
      ?.filter(waitingListPlace => waitingListPlace.product !== null && waitingListPlace.product !== undefined)
      .map(waitingListPlace => waitingListPlace.product as Product) ?? []

    if (productsWaitingListPlaces.length === 0) {
      return []
    }

    const distinctProductsMap = new Map()
    productsWaitingListPlaces.forEach(product => {
      distinctProductsMap.set(product.id, product)
    })

    const distinctProducts = Array.from(distinctProductsMap.values())
    return distinctProducts
  }

  async getConfigurationTableDataAsync (): Promise<void> {
    const configurationService = container.get<IConfigurationService>(SERVICE_IDENTIFIERS.IConfigurationService)
    const cancelResult: PlannerCausedByReason[] = await configurationService.getConfigurationByKeyAsync({ key: 'planner_proposition_cancel_reasons', date: new Date() })
    this.cancelReasons = cancelResult

    const revokeResult: PlannerCausedByReason[] = await configurationService.getConfigurationByKeyAsync({ key: 'planner_proposition_revoke_reasons', date: new Date() })
    this.revokeReasons = revokeResult
  }

  onProposeClicked () : void {
    this.causedByReasonType = ''
    this.plannerActionError = false
    this.showProposeDialog = true
  }

  onCancelClicked () : void {
    this.causedByReasonType = ''
    this.plannerActionError = false
    this.showCancelDialog = true
  }

  onRevokeClicked () : void {
    this.causedByReasonType = ''
    this.plannerActionError = false
    this.showRevokeDialog = true
  }

  async OnWaitinglistPropositionProposedAsync () : Promise<void> {
    const updatedPropositionResult = await this.waitingListPropositionService.proposeCreatedWaitingListProposition({ waitingListPropositionId: this.currentProposition.id })
    const handleResult = await this.handleUpdatedProposition(updatedPropositionResult)
    this.showProposeDialog = !handleResult
  }

  async OnWaitinglistPropositionCancelledAsync () : Promise<void> {
    if (!this.invalidCausedByReason()) {
      const updatedPropositionResult = await this.waitingListPropositionService.cancelCreatedWaitingListProposition({
        waitingListPropositionId: this.currentProposition.id,
        causedByReasonType: this.causedByReasonType,
        causedByReasonComment: this.causedByReasonComment.trim() || null
      })
      const handleResult = await this.handleUpdatedProposition(updatedPropositionResult)
      this.showCancelDialog = !handleResult
    }
  }

  async OnWaitinglistPropositionRevokedAsync () : Promise<void> {
    if (!this.invalidCausedByReason()) {
      const updatedPropositionResult = await this.waitingListPropositionService.revokeProposedWaitingListProposition({
        waitingListPropositionId: this.currentProposition.id,
        causedByReasonType: this.causedByReasonType,
        causedByReasonComment: this.causedByReasonComment.trim() || null
      })
      const handleResult = await this.handleUpdatedProposition(updatedPropositionResult)
      this.showRevokeDialog = !handleResult
    }
  }

  invalidCausedByReason () : boolean {
    return this.causedByReasonType === '' || this.causedByReasonComment.trim().length > this.commentMaxLength
  }

  onPropositionActionCancelled () : void {
    this.showProposeDialog = false
    this.showCancelDialog = false
    this.showRevokeDialog = false
  }

  onCausedByReasonTypeChanged (reasonType: string) : void {
    this.causedByReasonType = reasonType
  }

  onCausedByReasonCommentChanged (reasonComment: string) : void {
    this.causedByReasonComment = reasonComment
  }

  async handleUpdatedProposition (updatedPropositionResult: WaitingListPropositionResponse) : Promise<boolean> {
    if (!updatedPropositionResult || !updatedPropositionResult.isSuccess) {
      this.plannerActionError = true
      return false
    }

    if (this.currentProposition) {
      this.plannerActionError = false
      const updatedProposition = await this.propositionService.getOnePropositionAsync(this.currentProposition.id)
      // Overwrite currentProposition values with new values to update the expanded proposition item
      this.currentProposition.status = updatedProposition.status ?? ''
      this.currentProposition.causedBy = updatedProposition.causedBy
      this.currentProposition.causedByReasonType = updatedProposition.causedByReasonType
      this.currentProposition.causedByReasonComment = updatedProposition.causedByReasonComment
      this.currentProposition.expiresOn = updatedProposition.expiresOn
      this.currentProposition.proposedOn = updatedProposition.proposedOn
      this.currentProposition.placement.reservations = updatedProposition.placement?.reservations ?? []
      this.currentProposition.placement.status = updatedProposition.placement?.status ?? ''
      this.onExpandedStatusChanged(updatedProposition.status as WaitingListPropositionStatus)
    }
    return true
  }

  @Emit('onExpandedStatusChanged')
  onExpandedStatusChanged (status: WaitingListPropositionStatus) : WaitingListPropositionStatus {
    return status
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/helpers.scss';

.row-data {
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

.flex-row-space-between {
  justify-content: space-between;
  gap: 16px;
}

.d-flex-full-width {
  display: flex;
  width: 100%;
}

.cancel-reason-data {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
}

.action-buttons {
  margin-left: auto;
}

::v-deep .data-component {
  margin-bottom: 24px;
    p {
    color: $partou-primary-black-eighty;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

::v-deep .data-labels {
  color: $partou-primary-black-seventy;
  border-bottom: 1px solid $partou-primary-salmon-fourty;
  width: fit-content;
  padding-bottom: 4px;
}
</style>
<style lang="scss">
@import '@/styles/variables/variables.scss';
@import '@/styles/helpers.scss';

.revoke-reason-options {
  .vs--searchable {
    .vs__dropdown-menu {
      .vs__dropdown-option {
        white-space: initial !important;
        overflow: unset !important;
        text-overflow: unset !important;
        height: unset !important;
      }
    }
  }
}
</style>
