<template>
  <div class="waitinglist-proposition-table-page">
    <div>
      <div class="d-flex pl-8 pt-4 pb-4 mb-6">
        <ServicePicker class="background-grey" v-model="tableOptions.filters.service" @input="onSelectedServiceChanged" :services="services" :isClearable="true"/>
        <WaitingListPropositionStatusPicker class="background-grey ml-8" v-model="tableOptions.filters.propositionStatus" @input="onSelectedStatusChanged"/>
        <PartouTextField v-model="tableOptions.filters.global" @input="onSearchChanged" :label="$t('table.searchLabel')" class="search ml-8 mt-5" :iconName="'$vuetify.icons.partouSearch'" :iconPosition="'right'" size="small"></PartouTextField>
      </div>
    </div>
    <PartouCard>
      <template slot="card-body">
        <PartouDataTable
          class="waitinglist-proposition-table"
          ref="partou-data-table"
          :headers="headers"
          :items="items"
          :totalCount="totalCount"
          :showExpand="true"
          :tableOptions="tableOptions"
          :isLoading="isLoading"

          @expandedChanged="onExpandedChanged"
          @onOptionsChanged="onTableOptionsChangedAsync">
          <!-- custom column templates -->
          <template #item.data-table-exclamation="{ item }"> <!-- eslint-disable-line -->
            <div class="exclamation-icon-wrapper">
              <component :is="item.status === 'Created' ? 'PartouExclamationMarkVue' : ''"></component>
            </div>
          </template>
          <template #item.child.fullName="{ item }" class="child-full-name"> <!-- eslint-disable-line -->
            <template v-if="item.id === expandedItem.id">
              <h3>
                {{ item.locationInquiry.child.firstName }} {{ item.locationInquiry.child.lastName }}
              </h3>
            </template>
            <template v-else>
              {{ item.locationInquiry.child && item.locationInquiry.child.firstName }} {{ item.locationInquiry.child && item.locationInquiry.child.lastName }}
            </template>
          </template>
          <template #item.child.dateOfBirth="{ item }"> <!-- eslint-disable-line -->
            {{ item.locationInquiry.child && getFormattedDate(item.locationInquiry.child.dateOfBirth) }}
          </template>
          <template #item.createdAt="{ item }"> <!-- eslint-disable-line -->
            {{ getFormattedDate(item.createdAt, true) }}
          </template>
          <template #item.startDate="{ item }"> <!-- eslint-disable-line -->
            {{ getFormattedDate(item.placement.validFrom) }}
          </template>
          <template #item.location="{ item }"> <!-- eslint-disable-line -->
           {{ item.locationInquiry.service.name }}
          </template>
          <template #item.reservations="{ item }"> <!-- eslint-disable-line -->
            <div v-html="getStatusOfDayOfWeek(item)" />
          </template>
          <template #item.status="{ item }"> <!-- eslint-disable-line -->
            <div class="flex-row status-row">
              <div v-html="getCurrentPropositionStatus(item)"></div>
              <div v-if="hasUnsupportedProducts(item)" style="margin-left: auto;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon class="error-icon" :size="20">info_outline</v-icon>
                    </span>
                  </template>
                  <p class="text-center white--text mt-4">{{ $t('waitingListPropositions.table.expanded.productUnsupported') }}</p>
                </v-tooltip>
              </div>
            </div>
          </template>

          <!-- expand template -->
          <template v-slot:expanded-item="{ headers }">
            <td class="no-border pa-0" :colspan="headers.length">
              <div v-if="isLoading" class="d-flex justify-center flex-row">
                <v-progress-circular indeterminate></v-progress-circular>
              </div>
              <div v-else>
                <span class="expanded-divider"></span>
                <WaitingListPropositionTableExpanded :proposition="expandedItem" :serviceProducts="serviceProducts" @onExpandedChanged="onExpandedChanged" @onExpandedStatusChanged="onExpandedStatusChanged" />
              </div>
            </td>
          </template>
        </PartouDataTable>
      </template>
    </PartouCard>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Ref } from 'vue-property-decorator'
import { DataTableHeader } from '@/components/PartouComponents/PartouDataTable'
import PartouDataTable from '@/components/PartouComponents/PartouDataTable/PartouDataTable.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { DayOfWeek, PlacementStatus, Service, ServiceProduct, WaitingListProposition } from '@/models'
import WaitingListPropositionTableExpanded from './Expanded/WaitingListPropositionTableExpanded.vue'
import ServicePicker from '@/components/ServicePicker'
import WaitingListPropositionStatusPicker from '@/components/WaitingListPropositionStatusPicker/WaitingListPropositionStatusPicker.vue'
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import BaseEntityTable from '@/pages/_base/BaseEntityTable'
import PartouTextField from '@/components/PartouComponents/PartouTextField.vue'
import { getShortenedDayName, parseStringToDayOfWeek } from '@/models/enums/DayOfWeek'
import WaitingListPropositionStatus from '@/models/enums/WaitingListPropositionStatus'
import Actor from '@/models/enums/Actor'
import WaitingListPropositionTableOptions from './WaitingListPropositionTableOptions'
import PartouExclamationMarkVue from '@/components/PartouComponents/Icons/PartouExclamationMark.vue'
import { getFormattedDate } from '@/utils/dateUtils'

@Component({
  components: { ServicePicker, WaitingListPropositionStatusPicker, PartouCard, PartouDataTable, DaysCoverage, WaitingListPropositionTableExpanded, PartouTextField, PartouExclamationMarkVue }
})
export default class WaitingListPropositionTable extends BaseEntityTable<WaitingListProposition> {
  @Prop({ required: true })
  items!: Partial<WaitingListProposition>[]

  @Prop({ required: true })
  services!: Service[]

  @Prop({ required: true })
  serviceProducts!: ServiceProduct[]

  @Prop({ required: true, default: () => { return {} } })
  expandedItem! : WaitingListProposition

  @Ref('partou-data-table')
  partouDataTable!: PartouDataTable<WaitingListProposition>

  headers: DataTableHeader[] = [
    { text: '', value: 'data-table-exclamation', width: '2%', sortable: false },
    { text: 'waitingListPropositions.table.columns.childName', value: 'child.fullName', sortBy: 'locationInquiry.child.fullName', width: '14%' },
    { text: 'waitingListPropositions.table.columns.dateOfBirth', value: 'child.dateOfBirth', sortBy: 'locationInquiry.child.dateOfBirth', width: '12%' },
    { text: 'waitingListPropositions.table.columns.startDate', value: 'startDate', sortBy: 'startDate', width: '12%' },
    { text: 'waitingListPropositions.table.columns.createdAt', value: 'createdAt', sortBy: 'createdAt', width: '12%' },
    { text: 'waitingListPropositions.table.columns.location', value: 'location', sortBy: 'locationInquiry.service.name', width: '12%' },
    { text: 'waitingListPropositions.table.columns.days', value: 'reservations', width: '17%', sortable: false },
    { text: 'waitingListPropositions.table.columns.status', value: 'status', width: '17%', sortable: false },
    { text: '', value: 'data-table-expand', width: '2%', align: 'center' }
  ]

  tableItems : Partial<WaitingListProposition>[] = []
  tableOptions : WaitingListPropositionTableOptions = {
    sortBy: ['status_rank', 'createdAt'],
    sortDesc: [false, true],
    page: 1,
    itemsPerPage: 50,
    itemsPerPageOptions: [5, 10, 25, 50, 100],
    filters: {}
  }

  updatedStatus: WaitingListPropositionStatus | null = null

  async mounted () : Promise<void> {
    await this.onTableOptionsChangedAsync(this.tableOptions)
  }

  getFormattedDate (date: Date, withTime = false): string {
    return getFormattedDate(date, withTime)
  }

  getCurrentPropositionStatus (proposition: WaitingListProposition) : string {
    let causedByString
    if (this.updatedStatus !== null && proposition.id === this.expandedItem.id) {
      proposition.status = this.updatedStatus
      this.updatedStatus = null
    }
    switch (proposition.status) {
    case WaitingListPropositionStatus.Created:
      return '<span class="new-status">' + this.$t('services.waitingList.waitingListPropositionStatus.new').toString() + '</span>'
    case WaitingListPropositionStatus.Proposed:
      return this.$t('services.waitingList.waitingListPropositionStatus.proposed').toString()
    case WaitingListPropositionStatus.Declined:
      causedByString = proposition.causedBy === Actor.Parent ? this.$t('waitingListPropositions.table.parent') : this.$t('waitingListPropositions.table.planner')
      return this.$t('services.waitingList.waitingListPropositionStatus.declined').toString() + ' (' + causedByString + ')'
    case WaitingListPropositionStatus.Expired:
      return this.$t('services.waitingList.waitingListPropositionStatus.expired').toString()
    case WaitingListPropositionStatus.Cancelled:
      return this.$t('services.waitingList.waitingListPropositionStatus.cancelled').toString()
    case WaitingListPropositionStatus.Revoked:
      return this.$t('services.waitingList.waitingListPropositionStatus.revoked').toString()
    case 'Accepted':
      if (proposition.placement.status === PlacementStatus.Definitive) {
        return this.$t('services.waitingList.waitingListPropositionStatus.completed').toString()
      } else {
        return this.$t('services.waitingList.waitingListPropositionStatus.processing').toString()
      }
    default:
      return ''
    }
  }

  usesFlexkidsProducts (proposition: WaitingListProposition) : boolean {
    return proposition.locationInquiry?.waitingListPlaces?.some(waitingListPlace => waitingListPlace.product != null) ?? false
  }

  hasUnsupportedProducts (proposition: WaitingListProposition) : boolean {
    if (!this.usesFlexkidsProducts(proposition)) {
      return false
    }

    for (const waitingListPlace of proposition.locationInquiry.waitingListPlaces) {
      const product = waitingListPlace.product
      if (product) {
        const serviceId = proposition.locationInquiry.booking.serviceId ?? ''
        const serviceProduct = this.serviceProducts.find(serviceProduct => serviceProduct.productId === product.id && serviceProduct.serviceId === serviceId)
        if (!serviceProduct?.isBookable) {
          return true
        }
      }
    }

    return false
  }

  getStatusOfDayOfWeek (proposition: WaitingListProposition) : string {
    const reservations = proposition.placement.reservations
    const combinedList: Record<DayOfWeek, string> = { Monday: '', Tuesday: '', Wednesday: '', Thursday: '', Friday: '', Saturday: '', Sunday: '' }
    const reservationDays = new Set(reservations.flatMap(reservation => parseStringToDayOfWeek(reservation.dayOfWeek)))
    reservationDays.forEach(day => {
      combinedList[day] = `<span>${this.firstToUpper(getShortenedDayName(day))}</span> `
    })

    return Object.values(combinedList).join('')
  }

  getStatusElement (status: WaitingListPropositionStatus) : string {
    let template = '<span></span>'
    if (status === 'Created') {
      template = '<PartouExclamationMarkVue />'
    }
    return template
  }

  firstToUpper (dayLabel: string):string {
    return dayLabel[0].toUpperCase() + dayLabel.substring(1)
  }

  @Emit('onExpandedChanged')
  onExpandedChanged (items: Partial<WaitingListProposition>[]) : Partial<WaitingListProposition>[] {
    return items
  }

  onExpandedStatusChanged (updatedStatus: WaitingListPropositionStatus): void {
    this.updatedStatus = updatedStatus
  }

  onSelectedStatusChanged (): void {
    this.partouDataTable.onFilterChanged()
  }

  onSelectedServiceChanged () : void {
    this.partouDataTable.onFilterChanged()
  }

  onSearchChanged () : void {
    this.partouDataTable.onFilterChanged()
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';

  // !!! NOTE: STYLING IS NOT SCOPED
  .waitinglist-proposition-table-page {
    .search {
      .v-input__control .v-input__slot {
        background: transparent !important;
        box-shadow: none;
        border: 1px solid #BCBCBB !important;
      }

      .v-input__slot::before {
        display:none;
      }

      .v-input__slot::after {
        display:none;
      }

      .v-text-field__details {
        display: none;
      }

      .v-text-field__slot {
        input {
          color: $partou-primary-black-seventy;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
  .search {
    max-width: 300px !important;
    width: 300px !important;
    margin-top: 26px !important;
  }

  .waitinglist-proposition-table {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .expanded-divider {
    background-color: $partou-primary-salmon-sixty;
    height: 1px;
    display: flex;
  }

  .new-status {
    font-weight: bold;
    color: $partou-primary-orange;
  }

  .exclamation-icon-wrapper {
    min-width: 8px;
  }

  .no-border {
    border: unset;
  }

  .flex-row {
    display: flex;
    gap: 8px;
  }

  .error-icon {
    color: $partou-primary-salmon;
    margin-top: -2px;
    transform: rotate(180deg);
  }

  .status-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .child-full-name {
    color: $partou-primary-black-ninety;
  }

  h3 {
    color: $partou-primary-black-ninety;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
  }
</style>
